<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="url"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-bell"/> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Ajustes</strong>
    </CDropdownHeader>
    <CDropdownItem
      @click="goToProfile">
      <CIcon name="cil-user"/> Mi Perfil
    </CDropdownItem>
    <!-- <CDropdownItem
      >
      <CIcon name="cil-pencil" /> Editar perfil
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownDivider/>
    <!-- <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem> -->
    <CDropdownItem 
      @click="logout">
      <CIcon name="cil-lock-locked" /> Cerrar sesión
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import firebase from 'firebase';
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      url: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
    }
  },
  created(){
    let userId = this.$store.getters.userId;
    let userProfile = this.$store.getters.userProfile
    if( userProfile != null && userProfile != ''){
      userProfile = JSON.parse(userProfile);
      if (userProfile && userProfile.profile_image_url){
        this.url = userProfile.profile_image_url;
      }
    }
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'defUserProfile') {
        if (state.userProfile !== null && state.userProfile !== ''){
          let userProfile = JSON.parse(state.userProfile);
          if (userProfile && userProfile.profile_image_url_thumb){
            this.url = userProfile.profile_image_url;
          }
        }
      }
    });
  },
  methods: {
    logout: async function() {
      try {
        await firebase.auth().signOut()
        this.$store.commit('defUserId', null)
        // this.$store.commit('defUserProfile', null)
        this.$router.replace('/pages/main?cityId=1')
      } catch (error) {
          console.log(error)
      }
    },
    goToProfile() {
      console.log('goToProfile')
      this.$router.replace('/users/create')
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>