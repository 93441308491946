<template>
  <CFooter :fixed="false">
    <div>      
      <span class="ml-1">GoGuau &copy; {{new Date().getFullYear()}}.</span>
    </div>
    <div class="mfs-auto">      
      <!-- <a href="https://coreui.io/vue">CoreUI for Vue</a> -->
      <!-- <a  @click="goTo('terms')">
        <span class="text-link-color">
          Términos y Condiciones
        </span>
      </a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  methods: {
    goTo(to){
      if(to == 'terms'){                
        let route = this.$router.resolve({path: '/pages/terms'});
        window.open(route.href, '_blank');
      }
    }
  }
}
</script>
